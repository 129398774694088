var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CHeaderNav",
    { class: ["header-main-nav", { isMobile: _vm.isMobile }] },
    _vm._l(_vm.items, function (page, pageIndex) {
      return _c("router-link", {
        key: `pageIndex-${pageIndex}`,
        attrs: { to: page.route },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ isActive, href, navigate }) {
                return [
                  _c(
                    "CHeaderNavItem",
                    {
                      class: [
                        "header-main-nav__item",
                        page.class,
                        { isMobile: _vm.isMobile },
                      ],
                    },
                    [
                      _c(
                        "a",
                        {
                          class: [
                            "header-main-nav__link",
                            { isActive, isMobile: _vm.isMobile },
                          ],
                          attrs: { href: href },
                          on: {
                            click: function ($event) {
                              return _vm.setRoute($event, navigate, page)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "header-main-nav__link-inner" },
                            [_vm._v(" " + _vm._s(page.title) + " ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }