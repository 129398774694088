<template>
  <CDropdown
    ref="root"
    :class="'header-exchang-and-type'"
    :offset="[ 0, 2 ]"
    :show.sync="showDropdown"
  >
    <!-- Кнопка показа выпадающего списка: -->
    <template #toggler>
      <div
        :class="'header-exchang-and-type__open-button'"
        @mouseenter="openDropdown"
        @mouseleave="closedDropdownTimeout"
      >
        <div class="header-exchang-and-type__open-button-info">
          <!-- Иконка: -->
          <img
            :class="'header-exchang-and-type__logo _small'"
            :src="currentExchangeData.exchangeLogo"
            :alt="currentExchangeData.exchangeName"
          />
          <!-- Тип экарана: -->
          <span v-if="currentExchangeData.screenTypeName">
            {{ currentExchangeData.screenTypeName }}
          </span>
        </div>

        <!-- Стрелка: -->
        <CIcon
          :class="'header-exchang-and-type__open-button-arrow'"
          :name="'arrow-trends'"
          :width="8"
        />
      </div>
    </template>

    <!-- Выпадающий список бирж и типов экранов: -->
    <CRow
      :class="'header-exchang-and-type__container'"
      @mouseenter="openDropdown"
      @mouseleave="closedDropdownTimeout"
    >
      <CCol
        v-for="exchange in exchangesAndTypes"
        :class="'header-exchang-and-type__exchange'"
        :key="exchange.exchange"
      >
        <!-- Иконка: -->
        <img
          :class="'header-exchang-and-type__logo'"
          :src="exchange.logo"
          :alt="exchange.name"
        />

        <!-- Список типов экранов: -->
        <CNav :vertical="true">
          <CNavItem
            v-for="type in exchange.types"
            :class="'header-exchang-and-type__type'"
            :key="type.type"
            :active-class="'_active'"
            :to="resolveScreenRouteLocationRaw(exchange.exchange, type.type)"
          >
            {{ type.name }}
          </CNavItem>
        </CNav>
      </CCol>
    </CRow>
  </CDropdown>
</template>

<script>
import { CCol, CDropdown, CIcon, CNav, CNavItem, CRow } from '@coreui/vue-pro';

// Логотипы бирж:
// import okxLogoDark from '@/assets/img/okx-logo-dark.svg';
// import okxLogoLight from '@/assets/img/okx-logo-light.svg';
// import bibytLogoDark from '@/assets/img/bybit-logo-dark.svg';
// import bibytLogoLight from '@/assets/img/bybit-logo-light.svg';
import binanceLogoDark from '@/assets/img/binance-logo-dark.svg';
import binanceLogoLight from '@/assets/img/binance-logo-light.svg';

// Статичные данные:
const staticScreenTypes = [
  { type: 'spot', name: 'SPOT' },
  { type: 'futures', name: 'FUTURES' },
];

// Имена роутов разрешенные для резолвина функцией `resolveScreenRouteLocationRaw`:
const ALLOWER_ROUTE_NAMES = [
  'trends',
  'screener',
  'tablesview',
  'graphsview',
  'trendsview',
  'treemap',
];

export default {
  data: () => ({
    showDropdown: false,
    closeTimeout: null,
  }),

  watch: {
    '$route': {
      deep: true,
      immediate: true,
      handler (route) {
        this.$store.commit('setLastExchange', route.params.exchange);
        this.$store.commit('setLastScreen', route.params.screen);
      }
    },
  },

  computed: {
    // Получить флаг темной темы:
    darkMode() {
      return this.$store.getters.darkMode;
    },
    // Набор бирж и типов экранов:
    exchangesAndTypes () {
      return [
        {
          logo: this.darkMode ? binanceLogoDark : binanceLogoLight,
          name: 'BINANCE',
          exchange: 'binance',
          types: staticScreenTypes,
        },
        // {
        //   logo: this.darkMode ? bibytLogoDark : bibytLogoLight,
        //   name: 'Bybit',
        //   exchange: 'bybit',
        //   types: staticScreenTypes,
        // },
        // {
        //   logo: this.darkMode ? okxLogoDark : okxLogoLight,
        //   name: 'OKX',
        //   exchange: 'okx',
        //   types: staticScreenTypes,
        // },
      ];
    },
    // Получить данные текущей выбранной биржи и типа экрана:
    currentExchangeData() {
      const exchange = this.exchangesAndTypes.find(item => item.exchange === this.$store.getters.getLastExchange);
      const screen = exchange?.types.find(item => item.type === this.$store.getters.getLastScreen);

      return {
        exchangeName: exchange?.name,
        exchangeLogo: exchange?.logo,
        screenTypeName: screen?.name,
      };
    },
  },

  methods: {
    /**
     * Сформировать роут для экрана по бирже и типу.
     * @param {string} exchange - Биржа.
     * @param {string} screen - Тип экрана.
     * @returns {RouteLocationRaw}
     */
    resolveScreenRouteLocationRaw(exchange, screen) {
      const currentRouteName = this.$route.name;
      const isAllowedRouteName = ALLOWER_ROUTE_NAMES.includes(currentRouteName);
      return {
        name: isAllowedRouteName ? currentRouteName : 'screener',
        params: {
          exchange: exchange,
          screen: screen,
          lang: this.$router.currentRoute.lang,
        },
      };
    },
    /**
     * Открыть дропдаун при наведении. Создает слушатель для закрытия
     * дропдауна при выходе мышки за пределы дропдауна.
     */
    openDropdown () {
      clearTimeout(this.closeTimeout);
      this.showDropdown = true;
    },
    /**
     * Закрыть дропдаун при выходе мышки за пределы дропдауна.
     */
    closedDropdownTimeout () {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = setTimeout(() => {
        this.showDropdown = false;
      }, 500);
    },
  },

  components: {
    CDropdown,
    CNavItem,
    CIcon,
    CNav,
    CRow,
    CCol,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/includes/dark-schema";

// Плейсхолдер стилей типа экрана:
%type-styles {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
}

.header-exchang-and-type {
  display: flex;
  align-items: center;

  // Дочерние:
  & .dropdown-menu {
    width: max-content;
    padding: 24px !important;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2) !important;

    // Модификаторы:
    @include dark-schema {
      background-color: #24252F;
    }
  }

  & .nav {
    min-width: 120px;
  }

  & .nav-link {
    padding-left: 0 !important;
  }

  // Элементы:
  &__open-button {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 0;
    border: 0;
    text-align: left;
    color: rgba(#3c4b64, 0.45) !important;
    outline: none !important;
    background: none;

    // Модификаторы:
    @include dark-schema {
      color: rgba(#fff, 0.25) !important;
    }
  }

  &__open-button-info {
    @extend %type-styles;

    // Дочерние:
    & > * + * {
      display: block;
    }
  }

  &__open-button-arrow {
    transform: rotate(180deg);

    // Модификаторы:
    .show & {
      transform: rotate(0);
    }
  }

  &__logo {
    height: 24px;
    width: auto;

    // Модификаторы:
    &._small {
      height: 16px;
    }
  }

  &__exchange {
    & + & {
      margin-left: 40px;
      padding-left: 40px;
      border-left: 2px solid #f0f0f0;

      // Модификаторы:
      @include dark-schema {
        border-color: #282933;
      }
    }

    // Дочерние:
    & > * + * {
      margin-top: 24px;
    }
  }

  &__type {
    // Модификаторы:
    @include dark-schema {
      color: #fff;
    }

    // Дочерние:
    & > a {
      color: inherit !important;
      @extend %type-styles;

      // Состояния:
      &:hover {
        opacity: 0.5;
      }

      // Модификаторы:
      &._active {
        opacity: 0.45;

        @include dark-schema {
          opacity: 0.25;
        }
      }
    }
  }
}
</style>
