var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "overlay-footer",
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.$emit("close")
          },
        },
      },
      [
        _c(
          "CButton",
          {
            staticClass: "btn-nav shadow-none",
            attrs: { color: "secondary" },
            on: { click: _vm.prevStep },
          },
          [
            _c("CIcon", { attrs: { name: "cil-arrow-left" } }),
            _vm._v(" " + _vm._s(_vm.$t("onboarding.back")) + " "),
          ],
          1
        ),
        _c("span", { staticClass: "pagination" }, [
          _vm._v(
            " " +
              _vm._s(_vm.currentStepResult + 1) +
              " " +
              _vm._s(_vm.$t("onboarding.of")) +
              " " +
              _vm._s(_vm.stepsData.length) +
              " "
          ),
        ]),
        _c(
          "CButton",
          {
            staticClass: "btn-nav shadow-none",
            attrs: { color: "primary" },
            on: {
              click: function ($event) {
                return _vm.nextStep(false)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("onboarding.next")) + " "),
            _c("CIcon", { attrs: { name: "cil-arrow-right" } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }