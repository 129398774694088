var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: ["header-schema-toggle", { isMobile: _vm.isMobile }],
      on: { click: _vm.toggleDarkMode },
    },
    [_c("CIcon", { attrs: { name: _vm.iconName } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }