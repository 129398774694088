<template>
  <transition name="fade" appear>
    <div
      class="overlay"
      @click="$emit('close')"
    >
      <CButton
        class="c-btn shadow-none"
        color="primary"
        shape="pill"
        @click="$emit('close')"
      >
        ?
      </CButton>

      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "OnboardingOverlay"
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: rgba(0, 0, 0, .8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.c-btn {
  position: absolute;
  top: .75rem;
  right: 3rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
