var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stepData
    ? _c("div", { staticClass: "onboarding-step" }, [
        _vm.stepData.img
          ? _c("img", {
              staticClass: "onboarding-step__img",
              attrs: { src: _vm.stepData.img, alt: "" },
            })
          : _vm._e(),
        _vm.stepData.header
          ? _c("div", {
              staticClass: "onboarding-step__header",
              domProps: { innerHTML: _vm._s(_vm.stepData.header) },
            })
          : _vm._e(),
        _vm.stepData.text
          ? _c("div", {
              staticClass: "onboarding-step__text",
              domProps: { innerHTML: _vm._s(_vm.stepData.text) },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }