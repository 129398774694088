<template>
  <button
    :class="['header-schema-toggle', { isMobile }]"
    @click="toggleDarkMode"
  >
    <CIcon :name="iconName" />
  </button>
</template>

<script>
import { CIcon } from '@coreui/vue-pro'
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
    }),

    /**
     * Флаг темной темы.
     */
    darkMode () {
      return this.$store.getters.darkMode
    },

    /**
     * Имя иконки.
     *
     */
    iconName () {
      return this.darkMode ? 'cil-sun' : 'cil-moon'
    }
  },

  methods: {
    /**
     * Перекулючить темную тему.
     */
    toggleDarkMode () {
      this.$store.dispatch('setKey', {
        key: 'darkMode',
        namespace: 'global',
        value: !this.darkMode,
      });
    },
  },

  components: {
    CIcon,
  },

  data: () => ({}),
}
</script>

<style lang="scss">
.header-schema-toggle {
  height: 56px;
  width: 56px;
  border: none;
  color: inherit;
  background-color: transparent;

  // Модификаторы:
  &.isMobile {
    height: 28px;
    width: 28px;
  }

  // Состояния:
  &:not(:hover) {
    opacity: 0.7;
  }

  // Дочерние:
  & > .c-icon {
    width: 18px !important;
    height: 18px !important;
  }
}
</style>
