<template>
  <div v-if="stepData" class="onboarding-step">
    <img v-if="stepData.img" :src="stepData.img" alt="" class="onboarding-step__img">
    <div v-if="stepData.header" class="onboarding-step__header" v-html="stepData.header" />
    <div v-if="stepData.text" class="onboarding-step__text" v-html="stepData.text" />
  </div>
</template>

<script>
import OnboardingStepClass from "@/assets/js/onboarding/OnboardingStep.class";

export default {
  name: "OnboardingStep",
  props: {
    stepData: {
      type: [OnboardingStepClass, Object],
      required: true
    },
  }
}
</script>

<style scoped lang="scss">
.onboarding-step {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 70vw;
}

.onboarding-step__img {
  width: 6.5rem;
  height: 6.5rem;
  object-fit: contain;
  margin-bottom: 2rem;
}

.onboarding-step__header,
.onboarding-step__text {
  font-style: normal;
  font-weight: 500;
}

.onboarding-step__header {
  margin-bottom: 1.5rem;
  font-size: 1.875rem;
  line-height: 2.3rem;
  color: #FFFFFF;
  text-align: center;
}

.onboarding-step__text {
  font-size: 1rem;
  line-height: 170%;
  letter-spacing: 0.01em;
  color: #A4ACBA;
}
</style>
