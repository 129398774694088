<template>
  <div class="flex-shrink-0">
    <CButton
      class="c-btn shadow-none"
      color="dark"
      shape="pill"
      @click="setShowOnboarding(true)"
    >
      ?
    </CButton>

    <template v-if="showOnboarding">
      <portal to="onboarding-overlay">
        <onboarding-overlay
          :class="{ 'c-dark-theme': $store.getters.darkMode }"
          @close="setShowOnboarding"
        >
          <onboarding-step
            v-if="currentStepData && !currentStepData.tooltips.length"
            :step-data="currentStepData"
          />
        </onboarding-overlay>
      </portal>

      <portal to="onboarding-footer">
        <onboarding-footer
          :class="{ 'c-dark-theme': $store.getters.darkMode }"
          :current-step="currentStep"
          :steps-data="stepsData"
          :tippy-options="tippyOptions"
          :name="name"
          @close="setShowOnboarding"
          @update:currentStep="updateCurrentStep"
        />
      </portal>
    </template>
  </div>
</template>

<script>
import OnboardingOverlay from "@/components/onboarding/OnboardingOverlay";
import OnboardingFooter from "@/components/onboarding/OnboardingFooter";
import OnboardingStep from "@/components/onboarding/OnboardingStep";
import OnboardingStepClass from "@/assets/js/onboarding/OnboardingStep.class";
import axios from 'axios'
import {mapGetters} from "vuex";

export default {
  name: "OnboardingBtn",
  components: {
    OnboardingOverlay,
    OnboardingFooter,
    OnboardingStep,
  },
  props: {
    tippyOptions: {
      type: Object,
      default: () => ({})
    },
    /** @type {OnboardingStepClass[]} */
    stepsData: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      showOnboarding: false,
      currentStep: 0
    }
  },
  computed: {
    ...mapGetters({
      onboarding: 'onboarding'
    }),
    currentStepData() {
      const step = this.stepsData?.[this.currentStep]
      return step ? new OnboardingStepClass(step) : null
    }
  },
  created() {
    const stepNum = this.onboarding?.[this.name]

    this.currentStep = stepNum || 0
  },
  mounted() {
    this.$root.$on('firstLoadDelta', () => {
      this.setShowOnboarding(true)
      axios.get('/api/v1/active-first-view-delta')
    })
  },
  methods: {
    setShowOnboarding(show = false) {
      this.showOnboarding = show
    },
    updateCurrentStep(val) {
      this.currentStep = this.stepsData?.[val] ? val : 0

      this.$store.dispatch("setKey", {
        namespace: 'global',
        key: 'onboarding',
        value: {
          ...this.onboarding,
          [this.name]: this.currentStep
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-btn {
  width: 2rem;
  height: 2rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
