var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CDropdown",
    {
      ref: "root",
      class: "header-exchang-and-type",
      attrs: { offset: [0, 2], show: _vm.showDropdown },
      on: {
        "update:show": function ($event) {
          _vm.showDropdown = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function () {
            return [
              _c(
                "div",
                {
                  class: "header-exchang-and-type__open-button",
                  on: {
                    mouseenter: _vm.openDropdown,
                    mouseleave: _vm.closedDropdownTimeout,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "header-exchang-and-type__open-button-info",
                    },
                    [
                      _c("img", {
                        class: "header-exchang-and-type__logo _small",
                        attrs: {
                          src: _vm.currentExchangeData.exchangeLogo,
                          alt: _vm.currentExchangeData.exchangeName,
                        },
                      }),
                      _vm.currentExchangeData.screenTypeName
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.currentExchangeData.screenTypeName) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("CIcon", {
                    class: "header-exchang-and-type__open-button-arrow",
                    attrs: { name: "arrow-trends", width: 8 },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CRow",
        {
          class: "header-exchang-and-type__container",
          on: {
            mouseenter: _vm.openDropdown,
            mouseleave: _vm.closedDropdownTimeout,
          },
        },
        _vm._l(_vm.exchangesAndTypes, function (exchange) {
          return _c(
            "CCol",
            {
              key: exchange.exchange,
              class: "header-exchang-and-type__exchange",
            },
            [
              _c("img", {
                class: "header-exchang-and-type__logo",
                attrs: { src: exchange.logo, alt: exchange.name },
              }),
              _c(
                "CNav",
                { attrs: { vertical: true } },
                _vm._l(exchange.types, function (type) {
                  return _c(
                    "CNavItem",
                    {
                      key: type.type,
                      class: "header-exchang-and-type__type",
                      attrs: {
                        "active-class": "_active",
                        to: _vm.resolveScreenRouteLocationRaw(
                          exchange.exchange,
                          type.type
                        ),
                      },
                    },
                    [_vm._v(" " + _vm._s(type.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }