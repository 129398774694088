<template>
  <div class="item">
    <div class="flex-grow-1">
      <div class="item__head">
        <p class="item__name">{{data.label}}</p>
        <p class="item__price">{{data.price}}</p>
      </div>
      <div class="item__body">
        <div
          v-for="(param, i) in data.params"
          :key="i"
          class="item__value"
          :class="valueClassName(param)"
        >
          <!--<span class="text-muted">{{param.label}}</span>-->
          <div
            v-if="param.label === 'close_pct' ||
                  param.label === 'rsi14_pct' ||
                  param.label === 'tf_quote_volume_pct'"
            :class="{
              'text-danger': param.value < 0,
              'text-warning': param.value === 0,
              'text-success': param.value > 0
            }"
          >
            <span v-if="typeof param.value === 'number'">{{param.value}}%</span>
            <span v-else>{{param.value}}</span>
          </div>

          <div v-else-if="param.label === 'trend_ma200'">
            <div v-if="param.value === 3" class="d-flex">
              <span class="line-height-1 mr-1">MA</span>
              <span class="circle-block _danger" /></div>
            <div v-else-if="param.value === 2" class="d-flex">
              <span class="line-height-1 mr-1">MA</span>
              <span class="circle-block _warning" />
            </div>
            <div v-else-if="param.value === 1" class="d-flex">
              <span class="line-height-1 mr-1">MA</span>
              <span class="circle-block _success" />
            </div>
            <span v-else class="line-height-1">MA —</span>
          </div>

          <div v-else-if="param.label === 'color_rsi14'" class="d-flex">
            <span class="line-height-1 mr-1">RSI</span>
            <div class="circle-block-wrap">
              <span class="circle-block" :style="{background: param.value}" />
            </div>
          </div>

          <span v-else>{{param.value}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    valueClassName(param) {
      const deltaLabels = [
        'tf_quote_volume_delta_str',
        'tf1w_quote_volume_delta_str'
      ]
      if (deltaLabels.some(str => str === param?.label) && param?.value) {
        return `${param.value}`[0] === '-' ? '_danger' : '_success'
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.item {
  width: 11rem;
  font-size: 0.75rem;
  //border-right: 1px solid var(--dark);
  display: flex;
  align-items: center;
  font-weight: bold;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__price {
    margin-bottom: 0;
    margin-left: 0.2rem;
  }
  &__name {
    margin-bottom: 0;
  }
  &__body {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__value {
    margin-bottom: 0;
    margin-right: 0.2rem;
    white-space: nowrap;
    &._success {
      color: var(--success);
    }
    &._danger {
      color: var(--danger);
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:after {
    display: block;
    content: "";
    width: 1px;
    height: 1.5rem;
    background: var(--dark);
  }
}

.line-height-1 {
  line-height: 1;
}
.circle-block-wrap {
  line-height: 0;
  position: relative;
  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--second-card-bg);
    border-radius: 50%;
  }
  .circle-block {
    position: relative;
    z-index: 2;
  }
}
</style>
