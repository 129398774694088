<template>
  <CCard class="mb-0" v-if="select.length">
    <CCardBody
      class="line-runner"
      @mouseover="pauseAnimation = true"
      @mouseout="pauseAnimation = false"
    >
      <div
        ref="line"
        class="line"
        :class="{
          _animation: startAnimation,
          '_animation-pause': pauseAnimation
        }"
        :style="{animationDuration: time}"
      >
        <line-runner-item
          v-for="(item, i) in select"
          :key="i"
          :data="item"
        />
      </div>

      <!-- clone -->
      <div
        v-if="startAnimation"
        class="line _animation"
        :style="{animationDuration: time}"
        :class="{
          '_animation-pause': pauseAnimation
        }"
      >
        <line-runner-item
          v-for="(item, i) in select"
          :key="'clone' + i"
          :data="item"
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import toastr from "toastr";
import LineRunnerItem from '@/components/lineRunner/Item.vue'
import { mapGetters } from "vuex";
export default {
  name: "LineRunner",
  components: {
    LineRunnerItem
  },
  props: {
    isPaused: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      timer: null,
      timerIntervalDefault: 10000, // 60 sec
      cancelEvent: null,
      currentSlice: 0,
      startAnimation: false,
      velocity: 50,
      widthLine: 0,
      pauseAnimation: false,
    }
  },
  computed: {
    ...mapGetters({
      tablesData: 'tablesData',
      isBrowserTabActive: 'isBrowserTabActive',
      isGuest: 'user/isGuest'
    }),
    select () {
      return this.$store.getters['lineRunner/lineRunnerArr']
    },
    reload () {
      return this.$store.getters['lineRunner/reloadLineRunner']
    },
    time () {
      const val = this.widthLine / this.velocity
      return val + 's'
    }
  },
  watch: {
    reload() {
      this.$store.dispatch('lineRunner/getSelect').then(() => {
        this.getData()
      })
    },
    isBrowserTabActive(val) {
      if (!val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData && !this.$store.getters.getLineRunnerPaused) {
          this.getData()
        }
      }
    },
    tablesData: {
      deep: true,
      handler: function (val) {
        // if (val && Array.isArray(val) && !this.isBrowserTabActive) {
        this.$store.commit('lineRunner/SET_LIST', val)
        // }
      },
    },
    select() {
      setTimeout(() => {
        this.checkStartAnimation()
      }, 0)
    }
  },
  created () {
    this.getData()
  },
  mounted() {
    this.checkStartAnimation()
    window.addEventListener('resize', this.checkStartAnimation)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkStartAnimation)

    clearTimeout(this.timer)
    if (this.cancelEvent) {
      this.cancelEvent()
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('lineRunner/getList')
        .then(res => {
          this.checkStartAnimation()
          const delay = res?.data?.delay
          this.startTimer(delay)
        })
        .catch((e) => {
          const status = e?.response?.status
          if (e instanceof axios.Cancel) {
            toastr.remove()
          } else if (status !== 401 && status !== 403) {
            this.startTimer()
          }
        })
    },
    startTimer (delay) {
      if(!this.isBrowserTabActive) return
      let localDelay = this.timerIntervalDefault
      if(this.isGuest){
        // каждую шестую минуту
        localDelay = 360000 - Date.now() % 300000
        if(localDelay < this.timerIntervalDefault){
          localDelay = this.timerIntervalDefault
        }
      } else if (delay && typeof delay === 'number') {
        localDelay = delay
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        if (this.loadingData) return
        this.getData()
      }, localDelay)
    },
    checkStartAnimation () {
      if (!this.$refs.line) {
        this.startAnimation = false
      } else {
        const widthScreen = document.documentElement.clientWidth
        this.widthLine = this.$refs.line.getBoundingClientRect().width
        // console.log('widthScreen', widthScreen)
        // console.log('widthLine', widthLine)
        this.startAnimation = this.widthLine > widthScreen
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.line-runner {
  padding: 0.2rem 0 0.1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.line {
  display: flex;
  &._animation {
    animation-name: move;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &._animation-pause {
      animation-play-state: paused;
    }
  }
}

@keyframes move {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
