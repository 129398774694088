var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CHeader",
    { ref: "headerRef", staticClass: "cheader justify-content-between" },
    [
      _c(
        "CHeaderBrand",
        {
          ref: "logoRef",
          class: "cheader-brand",
          attrs: {
            href: !_vm.isCurrentUrl ? _vm.homepageWithLang : null,
            to: _vm.isCurrentUrl ? _vm.homepageWithLang : null,
            target: _vm.isCurrentUrl ? "_self" : "_blank",
          },
        },
        [_c("LogoWrap", { attrs: { "small-on-mobile": true } })],
        1
      ),
      _c(
        "div",
        { class: "cheader-block-with-separator mr-auto" },
        [
          _c("HeaderExchangAndType"),
          _c("HeaderMainNav", {
            class: "cheader-block-with-separator",
            attrs: { items: _vm.mainNavItems },
          }),
        ],
        1
      ),
      _c("HeaderSocials", {
        attrs: {
          items: _vm.mainSocialsItems,
          "hide-about-us": _vm.teleportAboutUs,
        },
      }),
      _c(
        "CHeaderNav",
        { ref: "secondMenuRef", staticClass: "cheader-nav" },
        [
          _c("HeaderSchemaToggle", { staticClass: "_mobile-hidden" }),
          _vm.isGuest
            ? _c(
                "CHeaderNavLink",
                { staticClass: "custom-nav-item" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "d-flex",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleShowLoginModal({ show: true })
                        },
                      },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-lock-locked" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$t("formLogin.signIn"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "CDropdown",
            {
              staticClass: "profile",
              attrs: {
                show: _vm.showDropdown,
                customPopperOptions: _vm.customPopperOptions,
                inNav: "",
              },
              on: {
                "update:show": function ($event) {
                  _vm.showDropdown = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "toggler",
                  fn: function () {
                    return [
                      _c(
                        "CHeaderNavLink",
                        {
                          staticClass:
                            "custom-nav-item custom-nav-item--profile",
                        },
                        [
                          _c("CIcon", {
                            staticClass: "mobile-menu-open _mobile-tablet-only",
                            attrs: { name: "menu" },
                          }),
                          _c("CIcon", {
                            staticClass:
                              "mobile-menu-close _mobile-tablet-only",
                            attrs: { name: "cil-x" },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "_mobile-tablet-hidden font-weight-bold",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$store.state.user.userData.name ||
                                      "Профиль"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("CIcon", {
                            staticClass:
                              "arrow-bottom arrow-bottom--desktop _mobile-tablet-hidden",
                            attrs: { name: "arrow-trends", width: "0.5rem" },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                {
                  class: [
                    "social-links-wrap d-flex align-items-center",
                    {
                      "justify-content-end": _vm.isMobile,
                      isMobile: _vm.isMobile,
                    },
                  ],
                },
                [
                  _c("HeaderSocials", {
                    attrs: {
                      items: _vm.burgerSocialsItems,
                      "hide-about-us": true,
                    },
                  }),
                  _c("HeaderSchemaToggle", { staticClass: "_mobile-only" }),
                ],
                1
              ),
              _c("CDropdownDivider", { staticClass: "_mobile-only" }),
              [
                _c("HeaderMainNav", {
                  attrs: {
                    items: _vm.burgerNavItems,
                    "is-mobile": true,
                    showDropdown: _vm.showDropdown,
                  },
                  on: {
                    "update:showDropdown": function ($event) {
                      _vm.showDropdown = $event
                    },
                    "update:show-dropdown": function ($event) {
                      _vm.showDropdown = $event
                    },
                  },
                }),
                _vm.teleportAboutUs
                  ? _c(
                      "CDropdownItem",
                      {
                        class: "nav-subitem",
                        attrs: { to: { name: "about-us" } },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("header.aboutUs")) + " ")]
                    )
                  : _vm._e(),
                _c("CDropdownDivider"),
              ],
              _vm._l(_vm.dropdownMenuItems, function (item, i) {
                return [
                  item.show
                    ? [
                        item.name
                          ? _c(
                              "CDropdownItem",
                              {
                                key: i,
                                staticClass: "nav-subitem",
                                attrs: { to: { name: item.name } },
                              },
                              [
                                item.iconName
                                  ? _c("CIcon", {
                                      attrs: { name: item.iconName },
                                    })
                                  : _vm._e(),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.i18nKey
                                        ? _vm.$t(item.i18nKey)
                                        : item.label || ""
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              "CDropdownItem",
                              {
                                key: i,
                                staticClass: "nav-subitem",
                                attrs: {
                                  href: _vm.$t(item.path),
                                  target: "_blank",
                                },
                              },
                              [
                                item.iconName
                                  ? _c("CIcon", {
                                      attrs: { name: item.iconName },
                                    })
                                  : _vm._e(),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.i18nKey
                                        ? _vm.$t(item.i18nKey)
                                        : item.label || ""
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ]
                    : _vm._e(),
                ]
              }),
              _c("CDropdownDivider"),
              _vm.isGuest
                ? _c(
                    "CDropdownItem",
                    {
                      staticClass: "nav-subitem",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.toggleShowLoginModal({ show: true })
                        },
                      },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-lock-locked" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$t("formLogin.signIn"))),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "CDropdownItem",
                    {
                      staticClass: "nav-subitem",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logoutBtnClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-lock-locked" } }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$t("header.logout"))),
                      ]),
                    ],
                    1
                  ),
            ],
            2
          ),
          _vm.showOnboarding
            ? _c("onboarding-btn", {
                staticClass: "onboarding-btn",
                attrs: { "steps-data": _vm.onboardingSteps, name: "delta" },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }