var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CNav",
    { class: ["header-socials", { isMobile: _vm.isMobile }] },
    _vm._l(_vm.listItems, function (item, itemIndex) {
      return _c(
        "CNavItem",
        {
          key: itemIndex,
          class: [
            "header-socials__item",
            { isRoute: item.route, isMobile: _vm.isMobile },
          ],
          attrs: {
            "active-class": "isActive",
            to: item.route,
            href: item.route ? undefined : item.link,
            target: item.route ? undefined : "_blank",
          },
        },
        [
          _vm._v(" " + _vm._s(item.title) + " "),
          item.svg
            ? _c("span", {
                class: "header-socials__icon",
                domProps: { innerHTML: _vm._s(item.svg) },
              })
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }