import store from "@/store";

export const dropdownMenuItems = () => [
  {
    show: true,
    name: 'account',
    i18nKey: 'header.account',
    iconName: 'cil-user'
  },
  {
    show: true,
    name: 'settings',
    i18nKey: 'header.settings',
    iconName: 'cil-settings'
  },
  {
    show: store.getters['user/getPermissions']('view_notifications'),
    name: 'notifications',
    i18nKey: 'lk.header.notifications',
    iconName: 'cil-bullhorn'
  },
  {
    show: store.getters['user/getPermissions']('control_users'),
    name: 'permission',
    i18nKey: 'header.permissions',
    iconName: 'cil-lock-locked'
  },
  {
    show: true,
    name: 'lk-subscribe',
    i18nKey: 'header.subscribe',
    iconName: 'cil-money'
  },
  {
    show: store.getters['user/getPermissions']('control_tariffs'),
    name: 'subscribe-constructor',
    i18nKey: 'header.subscribeConstructor',
    iconName: 'cil-dollar'
  },
  {
    show: store.getters['user/getPermissions']('control-education'),
    name: 'training',
    i18nKey: 'header.training',
    iconName: 'cilEducation'
  },
  {
    show: store.getters['user/getPermissions']('view-education') || store.getters['user/getPermissions']('control-education') || store.getters['user/getPermissions']('show_curator'),
    name: 'lk-study',
    i18nKey: 'header.education',
    iconName: 'cil-book'
  },
  {
    show: store.getters['user/getPermissions']('show_curator'),
    name: 'curators',
    i18nKey: 'header.curator',
    iconName: 'curator'
  },
  {
    show: store.getters['user/getPermissions']('control_users'),
    name: 'authors',
    i18nKey: 'header.authors',
    iconName: 'cil-group'
  },
  {
    show: store.getters['user/authorId'],
    name: 'self-author',
    i18nKey: 'header.author',
    iconName: 'cil-group'
  },
  {
    show: store.getters['user/getPermissions']('control_tariffs'),
    name: 'video-encrypt',
    i18nKey: 'header.videoEncrypt',
    iconName: 'cilVideo'
  },
  {
    show: store.getters['user/getPermissions']('control_tariffs'),
    name: 'video-public',
    i18nKey: 'header.videoPublic',
    iconName: 'cilVideo'
  },
  {
    show: store.getters['user/getPermissions']('control_coins'),
    name: 'coin-list',
    i18nKey: 'header.coinList',
    iconName: 'cib-bitcoin'
  },
  {
    show: false/*store.getters['user/getPermissions']('control_invite_codes')*/,
    name: 'invite-code',
    i18nKey: 'header.inviteCode',
    iconName: 'keyhole'
  },
  {
    show: !store.getters['user/isGuest'],
    name: 'payments',
    i18nKey: 'header.payments',
    iconName: 'cil-dollar'
  },
  {
    show: store.getters['user/getPermissions']('update-transactions'),
    name: 'transactions',
    i18nKey: 'header.transactions',
    iconName: 'cil-wallet'
  },
  {
    show: store.getters['user/getPermissions']('show_analytics'),
    name: 'analytics',
    i18nKey: 'header.analytics',
    iconName: 'cil-chart-line'
  },
  {
    show: store.getters['user/getPermissions']('show_whitelist'),
    name: 'whitelist',
    label: 'Whitelist',
    iconName: 'cil-spreadsheet'
  },
  {
    show: true/*store.getters['user/getPermissions']('view-page-referrals')*/,
    name: 'lk-referrals',
    i18nKey: 'lk.referrals.title',
    iconName: 'cil-user-plus'
  },
  {
    show: store.getters['user/getPermissions']('control_users'),
    name: 'tg_notifications',
    i18nKey: 'lk.tg_notifications.title',
    iconName: 'cil-bullhorn'
  },
  {
    show: store.getters['user/getPermissions']('control_users'),
    name: 'add_trends',
    label: 'Тренды',
    iconName: 'cil-arrow-top'
  },
  {
    show: true,
    path: 'header.guide.path',
    i18nKey: 'header.guide.label',
    iconName: 'cil-book'
  },
  {
    show: store.getters['user/getPermissions']('control_users'),
    name: 'user-activity',
    label: 'Активность пользователей',
    iconName: 'cil-user'
  },
]
