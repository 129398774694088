<template>
  <transition name="fade" appear>
    <div class="overlay-footer" @click.self="$emit('close')">
      <CButton
        class="btn-nav shadow-none"
        color="secondary"
        @click="prevStep"
      >
        <CIcon name="cil-arrow-left"/>
        {{ $t('onboarding.back') }}
      </CButton>

      <span class="pagination">
        {{ currentStepResult + 1 }} {{ $t('onboarding.of') }} {{ stepsData.length }}
      </span>

      <CButton
        class="btn-nav shadow-none"
        color="primary"
        @click="nextStep(false)"
      >
        {{ $t('onboarding.next') }}
        <CIcon name="cil-arrow-right"/>
      </CButton>
    </div>
  </transition>
</template>

<script>
import tippy from "tippy.js";
import OnboardingStepClass from "@/assets/js/onboarding/OnboardingStep.class";
import {cloneDeep} from "lodash";

const DATA_STEP_NAME = 'data-onboarding-step'
const DATA_STEP_SELECTOR = 'data-onboarding-step-selector'

export default {
  name: "OnboardingFooter",
  props: {
    tippyOptions: {
      type: Object,
      default: () => ({})
    },
    /** @type {OnboardingStepClass[]} */
    stepsData: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      steps: [],
      stepsDataResult: [],
      observer: null,
      currentStepResult: 0,
      contentElement: null
    }
  },
  computed: {
    tippyOptionsResult() {
      return {
        showOnCreate: true,
        zIndex: 100003,
        trigger: 'manual',
        hideOnClick: false,
        allowHTML: true,
        theme: 'transparent',

        ...this.tippyOptions,
      }
    },
    activeClass() {
      return `onboarding-${this.name}--active`
    }
  },
  created() {
    this.stepsDataResult = cloneDeep(this.stepsData.map((el) => new OnboardingStepClass(el)))
    this.currentStepResult = this.currentStep
  },
  mounted() {
    this.$nextTick(() => {
      this.getSteps()
      this.nextStep(true)

      this.observer = new MutationObserver(() => {
        this.getSteps()
      })

      this.contentElement = document.querySelector('.c-body')

      if (this.contentElement) {
        this.contentElement.classList.add(this.activeClass)
        this.observer.observe(this.contentElement, {
          attributes: true,
          childList: true,
          subtree: true
        })
      }
    })
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }

    if (this.contentElement) {
      this.contentElement.classList.remove(this.activeClass)
    }

    this.stepsDataResult
      .flatMap(el => {
        return el.tippyInstances
      })
      .forEach(el => {
        if (el) {
          el?.destroy()
        }
      })
    this.stepsDataResult.forEach((el) => {
      el.tippyInstances.forEach(tippy => {
        if (tippy) {
          tippy?.destroy()
        }
      })
      this.removeStylesFromStep(el)
    })
  },
  methods: {
    getTippyContent({header, text}) {
      const headerHtml = `<div class="tippy-transparent-header">${header}</div>`
      const textHtml = `<div>${text}</div>`

      if (header && text) {
        return headerHtml + textHtml
      } else if (header) {
        return  headerHtml
      } else return textHtml
    },
    getSteps() {
      document.querySelectorAll(`[${DATA_STEP_NAME}]`).forEach(el => {
        const stepsStr = el.getAttribute(DATA_STEP_NAME)
        const stepsSelectorsStr = el.getAttribute(DATA_STEP_SELECTOR)
        let stepsSelectorsArr = []

        if (!stepsStr) return

        if (stepsSelectorsStr) {
          stepsSelectorsArr = stepsSelectorsStr.split('/')
        }

        const stepsArr = stepsStr.split('/').map(Number).filter(num => !isNaN(num))

        stepsArr.forEach((stepNum, index) => {
          if (stepsArr.length && this.stepsDataResult[stepNum - 1]) {
            const childElSelector = stepsSelectorsArr[index]

            if (childElSelector) {
              const childEl = el.querySelector(childElSelector)

              if (childEl) {
                this.$set(this.stepsDataResult[stepNum - 1], 'element', childEl)
              } else {
                this.$set(this.stepsDataResult[stepNum - 1], 'element', el)
              }
            } else {
              this.$set(this.stepsDataResult[stepNum - 1], 'element', el)
            }
          }
        })
      })
    },
    setTippyForStep(step, show = true) {
      if (step?.element) {
        if (step.tippyInstances.length) {
          step.tippyInstances.forEach(instance => {
            if (show) {
              instance.show()
            } else {
              instance.hide()
            }
          })
        } else if (show) {
          step.tooltips.forEach(el => {
            step.tippyInstances.push(
              tippy(step.element, {
                ...this.tippyOptionsResult,
                ...el,
                content: this.getTippyContent(el)
              })
            )
          })
        }
      }
    },
    addStylesForStep(step) {
      if (!step?.element) return

      const isStatic = window.getComputedStyle(step.element).position === 'static'

      step.element.style.setProperty('z-index', 100001)

      if (isStatic) {
        step.element.style.setProperty('position', 'relative')
        step.element.setAttribute('data-position', true)
      }
    },
    removeStylesFromStep(step) {
      if (!step?.element) return

      step.element.style.removeProperty('z-index')

      if (step.element.getAttribute('data-position')) {
        step.element.removeAttribute('data-position')
        step.element.style.removeProperty('position')
      }
    },
    changeStep(stepData) {
      this.stepsDataResult.forEach(step => {
        if (step.element) {
          this.setTippyForStep(step, false)

          this.removeStylesFromStep(step)
        }
      })

      if (stepData.element) {
        this.setTippyForStep(stepData)

        this.addStylesForStep(stepData)

        stepData.element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    },
    nextStep(init = false) {
      if (!init) {
        this.currentStepResult++
        this.$emit('update:currentStep', this.currentStepResult)
      }

      const stepData = this.stepsDataResult[this.currentStepResult]

      if (!stepData) {
        if (init) {
          this.currentStepResult = 0
          this.$emit('update:currentStep', 0)
        } else {
          this.$emit('close')
        }

        return
      }

      if (stepData.element || stepData.isBanner) {
        this.changeStep(stepData)
      } else if (this.currentStepResult < this.stepsDataResult.length - 1) {
        this.nextStep()
      } else {
        if (init) {
          this.currentStepResult = 0
          this.$emit('update:currentStep', 0)
        } else {
          this.$emit('close')
        }
      }
    },
    prevStep() {
      this.currentStepResult--
      this.$emit('update:currentStep', this.currentStepResult)

      const stepData = this.stepsDataResult[this.currentStepResult]

      if (!stepData) {
        this.$emit('close')

        return
      }

      if (stepData.element || stepData.isBanner) {
        this.changeStep(stepData)
      } else if (this.currentStepResult > 0) {
        this.prevStep()
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.overlay-footer {
  position: fixed;
  width: 100%;
  z-index: 100002;
  bottom: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  padding-right: 6rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pagination {
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-align: center;
  color: #FFFFFF;
}

.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/ svg {
    width: .7rem !important;
    height: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  &:first-child {
    /deep/ svg {
      margin-right: .6rem;
    }
  }

  &:last-child {
    /deep/ svg {
      margin-left: .6rem;
    }
  }
}
</style>
