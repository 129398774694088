var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.select.length
    ? _c(
        "CCard",
        { staticClass: "mb-0" },
        [
          _c(
            "CCardBody",
            {
              staticClass: "line-runner",
              on: {
                mouseover: function ($event) {
                  _vm.pauseAnimation = true
                },
                mouseout: function ($event) {
                  _vm.pauseAnimation = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  ref: "line",
                  staticClass: "line",
                  class: {
                    _animation: _vm.startAnimation,
                    "_animation-pause": _vm.pauseAnimation,
                  },
                  style: { animationDuration: _vm.time },
                },
                _vm._l(_vm.select, function (item, i) {
                  return _c("line-runner-item", {
                    key: i,
                    attrs: { data: item },
                  })
                }),
                1
              ),
              _vm.startAnimation
                ? _c(
                    "div",
                    {
                      staticClass: "line _animation",
                      class: {
                        "_animation-pause": _vm.pauseAnimation,
                      },
                      style: { animationDuration: _vm.time },
                    },
                    _vm._l(_vm.select, function (item, i) {
                      return _c("line-runner-item", {
                        key: "clone" + i,
                        attrs: { data: item },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }