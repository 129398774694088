var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-shrink-0" },
    [
      _c(
        "CButton",
        {
          staticClass: "c-btn shadow-none",
          attrs: { color: "dark", shape: "pill" },
          on: {
            click: function ($event) {
              return _vm.setShowOnboarding(true)
            },
          },
        },
        [_vm._v(" ? ")]
      ),
      _vm.showOnboarding
        ? [
            _c(
              "portal",
              { attrs: { to: "onboarding-overlay" } },
              [
                _c(
                  "onboarding-overlay",
                  {
                    class: { "c-dark-theme": _vm.$store.getters.darkMode },
                    on: { close: _vm.setShowOnboarding },
                  },
                  [
                    _vm.currentStepData && !_vm.currentStepData.tooltips.length
                      ? _c("onboarding-step", {
                          attrs: { "step-data": _vm.currentStepData },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "portal",
              { attrs: { to: "onboarding-footer" } },
              [
                _c("onboarding-footer", {
                  class: { "c-dark-theme": _vm.$store.getters.darkMode },
                  attrs: {
                    "current-step": _vm.currentStep,
                    "steps-data": _vm.stepsData,
                    "tippy-options": _vm.tippyOptions,
                    name: _vm.name,
                  },
                  on: {
                    close: _vm.setShowOnboarding,
                    "update:currentStep": _vm.updateCurrentStep,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }