<template>
  <CNav :class="['header-socials', { isMobile }]">
    <CNavItem
      v-for="(item, itemIndex) in listItems"
      :class="['header-socials__item', { isRoute: item.route, isMobile }]"
      :active-class="'isActive'"
      :key="itemIndex"
      :to="item.route"
      :href="item.route ? undefined : item.link"
      :target="item.route ? undefined : '_blank'"
    >
      {{ item.title }}

      <span
        v-if="item.svg"
        v-html="item.svg"
        :class="'header-socials__icon'"
      />
    </CNavItem>
  </CNav>
</template>

<script>
// Компоненты:
import { CNav, CNavItem } from '@coreui/vue-pro';
import { mapGetters } from 'vuex';

export default {
  props: {
    items: Array,
    hideAboutUs: Boolean
  },

  computed: {
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
    }),

    /**
     * Список элементов.
     */
    listItems () {
      const result = [ ...this.items ];

      if (!this.hideAboutUs) result.push({
        display: !this.hideAboutUs,
        title: this.$t('header.aboutUs'),
        route: { name: 'about-us'}
      });

      return result
    }
  },

  components: {
    CNav,
    CNavItem,
  }
}
</script>

<style lang="scss">
.header-socials {
  // Модификаторы:
  &.isMobile > * + * {
    margin-left: 1.2rem;
  }

  // Элементы
  &__item {
    width: 56px;
    height: 56px;

    // Состояния:
    &:hover {
      --text-color: inherit;
      --icon-color: var(--primary, #3686F6);
    }

    // Модификаторы:
    &.isRoute {
      width: auto;
    }

    &.isRoute:not(:hover) {
      opacity: 0.7;
    }

    &.isMobile:not(.isRoute) {
      width: 28px;
      height: 28px;
    }

    // Дочерние:
    & > a {
      color: var(--text-color, inherit) !important;
      text-decoration: none;
    }

    & > .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  &__icon svg {
    display: block;
    margin: auto;
    width: 1.5rem;
    height: 1.5rem;

    path, circle, polygon, g {
      transition: 150ms ease-out;
      fill: var(--icon-color, currentColor);
    }
  }
}
</style>
