var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "overlay",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      },
      [
        _c(
          "CButton",
          {
            staticClass: "c-btn shadow-none",
            attrs: { color: "primary", shape: "pill" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" ? ")]
        ),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }